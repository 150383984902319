.contentffd {
  display: flex;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: white;
  flex-wrap: wrap;
  padding: 24px;
  flex-direction: column;
}

.breadcrumb-header {
  margin-bottom: 24px;
}

.headerButton {
  padding: 0px !important;
  color: black !important;
}

.ant-radio-button-checked {
  background-color: black !important;
  color: white !important;
  border-color: black !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:hover {
  color: white !important;
}

.ant-radio-button-wrapper:hover {
  color: inherit !important;
}

.tablecontent {
  margin-top: 24px;
}

.ff-description {
  padding: 12px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
