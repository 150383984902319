.user-management-content {
  display: flex;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: white;
  flex-wrap: wrap;
  padding: 24px;
}

.ant-table-wrapper {
  width: 100% !important;
}

.ant-alert {
  width: 100% !important;
  margin-bottom: 24px !important;
}

.table-search {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.antd-button-override {
  width: 120px;
  margin-right: 24px;
}

.antd-button-override-success {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}
