.ant-card-head {
  background-color: #f0f0f0 !important;
  color: black !important;
}

.content {
  display: flex;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: white;
  flex-wrap: wrap;
  padding: 24px;
}

.ff-header {
  display: flex;
  justify-content: space-between;
}
