.flags-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: #f9fbfc;
}

.flags-container {
  margin-top: 40px;
  height: calc(100vh - 40px);
  width: 100%;
  display: flex;
  overflow: auto;
  padding: 20px 40px;
}

.menu-container {
  display: flex;
  width: 240px;
  height: fit-content;
  justify-content: center;
  position: sticky;
  top: 0;
}

.ant-menu-item {
  color: gray;
}

.ant-menu-item-selected {
  background-color: #f5f5f5 !important;
  color: black !important;
}

.ant-menu-item::after {
  border-right: 3px solid #000 !important;
}

.ant-menu-item:hover {
  color: black !important;
}

.content-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
}

.no-access-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.hidden-icon {
  font-size: 20px;
  margin-right: 16px;
}
